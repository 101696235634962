import { getFirebaseBackend } from "@/authUtils";
import { AsyncHelper } from "./AsyncHelper";
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { StandardAsyncResult as AsyncResult, ClientDownloadFileMeta } from "@claimsgate/core-types";
import firebase from "firebase/compat/app";
import store from "@/state/store";
import mime from "mime-types";

/**
 * Storage Service provides helper methods to work with Files in Claims Gate
 */
class StorageService {
  storage = getFirebaseBackend().firebaseStorage();

  /**
   * Calls to cloud function to get a file url from a path
   * @param {String} filePath - Path of the file to get the url for
   * @returns { Promise<string> }
   */
  async getDownloadLinkWithCloudFunction(filePath: string): Promise<string> {
    const fileRequest = getFirebaseBackend().firebaseFunctions().httpsCallable("fileRequest");

    const request = {
      requestType: "getFileUrl",
      path: filePath,
    };

    const { data: fileData } = await fileRequest(request);

    if (fileData.error) {
      throw new Error(fileData.message);
    }

    return fileData.url;
  }

  /**
   * Calls to cloud function to get a file url from a path
   * @param {String} filePath - Path of the file to get the url for
   * @returns { Promise<{url: string, name: string, mime: string}> }
   */
  async getFileFromIdWithCloudFunction(fileId): Promise<ClientDownloadFileMeta> {
    const fileRequest = getFirebaseBackend().firebaseFunctions().httpsCallable("fileRequest");

    const request = {
      requestType: "getFileUrl",
      fileId,
    };

    const { data: fileMeta } = (await fileRequest(request)) as { data: ClientDownloadFileMeta & { error?: any } };

    if (fileMeta.error || !fileMeta) {
      throw new Error(fileMeta.error) ?? new Error("Error getting file meta");
    }
    let extension;
    if (fileMeta.fileMime.includes("/")) {
      extension = mime.extension(fileMeta.fileMime);
    } else {
      // THre are some odd files in the system where the mime type is just the extension. I think this is coming from parseDsar, and possible yoti
      extension = fileMeta.fileMime;
    }

    fileMeta.fileExtension = extension === false ? "pdf" : extension;

    return fileMeta;
  }

  /**
   * Calls to cloud function to move a file from one location to another
   * @param {String} sourcePath - Path of the file to move
   * @param {String} destinationPath - Path to move the file to
   * @returns { Promise<boolean> }
   */
  async moveFileWithCloudFunction(sourcePath: string, destinationPath: string): Promise<boolean> {
    const fileRequest = getFirebaseBackend().firebaseFunctions().httpsCallable("fileRequest");

    const request = {
      requestType: "moveFile",
      path: sourcePath,
      destination: destinationPath,
    };

    const { data: fileData } = await fileRequest(request);

    if (fileData.error) {
      throw new Error(fileData.message);
    }

    return true;
  }

  /**
   * Calls to cloud function "fileRequest" to delete a file at a path
   * @param {String} filePath - Path of the file to delete
   * @returns { Promise<boolean> }
   */
  async deleteFileWithCloudFunction(filePath: string): Promise<boolean> {
    const fileRequest = getFirebaseBackend().firebaseFunctions().httpsCallable("fileRequest");

    const request = {
      requestType: "deleteFile",
      path: filePath,
    };

    const { data: fileData } = await fileRequest(request);

    if (fileData.error) {
      throw new Error(fileData.message);
    }

    return true;
  }

  /**
   * replaces the file path with the temporary file path if the path is in a secured area
   * @param {String} filePath
   * @returns {String}
   */
  getTemporaryFilePath(filePath: string): string {
    const pathParts = filePath.split("/").filter((part) => part.length > 0);
    if (["solicitors", "workspaces"].includes(pathParts[0])) {
      return `temp/${getFirebaseBackend().getAuthenticatedUser()?.uid}/${filePath}`;
    }
    return filePath;
  }

  shouldDownloadViaFileRequest(filePath) {
    const pathParts = filePath.split("/").filter((part) => part.length > 0);
    if (["solicitors"].includes(pathParts[0])) {
      return true;
    }
    return false;
  }
  /**
   * Uploads a file to Firebase Storage
   * @param {filePath} - The document path where to store the file in storage
   * @param {file} - The file object which contains the file to store
   * @returns { Promise<AsyncResult<boolean, never, any>> }
   */
  async uploadFile(filePath, file): Promise<AsyncResult<boolean, undefined, any>> {
    try {
      const storageRef = this.storage.ref();
      const path = this.getTemporaryFilePath(filePath);
      const documentRef = storageRef.child(path);

      const snapshot = documentRef.put(file);

      // Let vuex know about the progress
      snapshot.on(firebase.storage.TaskEvent.STATE_CHANGED, async (snapshot) => {
        let currentPercent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // For small files, only a 0 & 100 % event occur, so set the % to 1 so the loader shows
        if (currentPercent === 0) {
          currentPercent = 1;
        }

        console.log("Uploading file", file.name, currentPercent);

        await store.dispatch("form/setFileUploadProgress", { fileName: file.name, progress: currentPercent });

        // // If the file is small and the upload is already complete, introduce an artificial delay
        // if (file.size < 300 * 1024 && currentPercent === 1) {
        //   await new Promise((resolve) => setTimeout(resolve, 2000)); // 2000 ms = 2 seconds
        // }
      });

      const uploadResult = await snapshot;
      if (uploadResult && path != filePath) {
        // move the file to the correct location
        await this.moveFileWithCloudFunction(path, filePath);
      }

      await store.dispatch("form/setFileUploadProgress", { fileName: file.name, progress: 100 });
      if (uploadResult) {
        return AsyncHelper.onCompleted(true);
      } else {
        return AsyncHelper.onCompleted(false);
      }
    } catch (exception) {
      store.dispatch("form/setFileUploadProgress", { fileName: file.name, progress: 0 });
      console.log("Exception e", exception);
      return AsyncHelper.onException(exception);
    }
  }

  /**
   * Downloads a file from Firebase Storage and returns the blob
   */
  async downloadFile(filePath: string, meta: { name: string }): Promise<AsyncResult<File, undefined, any>> {
    try {
      const storageRef = this.storage.ref();
      const documentRef = storageRef.child(filePath);

      window.console.log("DocumentRef is:", documentRef);
      const url = await this.getDownloadLink(filePath);

      const response = await axios.get(url, { responseType: "arraybuffer" });

      const type = response.headers["content-type"];

      window.console.log("file is", type);

      let file: File;
      if (Object.keys(meta).length > 0) {
        file = new File([response.data], meta.name, { type: type });
      } else {
        file = new File([response.data], documentRef.name, { type: type });
      }

      window.console.log("file is", file);

      return AsyncHelper.onCompleted(file);
    } catch (exception) {
      window.console.log("res.blob", exception);
      return AsyncHelper.onException(exception);
    }
  }

  /**
   *
   * @param { String} filePath
   * @returns { Promise<AsyncResult<boolean, never, boolean>>}
   */
  async deleteFile(filePath: string): Promise<AsyncResult<boolean, undefined, boolean>> {
    if (this.getTemporaryFilePath(filePath) != filePath) {
      // delete the file with cloud function
      try {
        await this.deleteFileWithCloudFunction(filePath);
        return AsyncHelper.onCompleted(true);
      } catch (exception) {
        return AsyncHelper.onException(exception);
      }
    } else {
      const storageRef = this.storage.ref();
      const documentRef = storageRef.child(filePath);
      try {
        await documentRef.delete();
        return AsyncHelper.onCompleted(true);
      } catch (exception) {
        console.error("storage service delete threw", exception);
        return AsyncHelper.onException(false);
      }
    }
  }

  /**
   * @param { String } filePath Firebase storage path
   * @returns URL download link for the file from given path
   */
  async getDownloadLink(filePath: string) {
    // check if the temporary file path
    if (this.shouldDownloadViaFileRequest(filePath)) {
      // get the download link from the cloud function
      try {
        return await this.getDownloadLinkWithCloudFunction(filePath);
      } catch (exception) {
        console.error(exception);
      }
    }

    const storageRef = this.storage.ref(filePath);
    let url;
    try {
      url = await storageRef.getDownloadURL();
      console.log("URL is", url);
      /* eslint-disable no-empty */
    } catch (e) {
      console.log("LINK threw", e);
      url = undefined;
      // Failed to get file download link
    }
    return url;
  }

  async openStoragePath(path) {
    const storageRef = this.storage.ref(path);
    try {
      const url = await storageRef.getDownloadURL();
      window.open(url, "_blank");
      // eslint-disable-next-line no-empty
    } catch {}
  }

  async downloadFileToBrowser(url, fileName) {
    await fetch(url)
      .then((resp) => resp.blob())
      .then((blob) => {
        // Allow for the downloaded file's binary large object
        // to be downlodable on this page
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;

        if (fileName.split(".").length < 2) {
          // File does not have an extension.
          // adding pdf as fallback
          a.download = fileName + ".pdf";
        } else {
          a.download = fileName;
        }

        document.body.appendChild(a);

        a.click();
        window.URL.revokeObjectURL(url);
      });
  }
}

export { StorageService };
