import { getFirebaseBackend } from "@/authUtils.js";
import { StorageService } from "./StorageService";
import { DateService } from "./DateService";
import firebase from "firebase/compat/app";
import mime from "mime-types";

/**
 * Data Service maintains a cached copy of commonly queried
 * Firestore documents to improve performance
 */
class DataService {
  _cache;
  _collectionName;
  _db = null;
  storageService = new StorageService();
  dateService = new DateService();
  FieldValue = firebase.firestore.FieldValue;
  constructor(db) {
    if (db) {
      this._db = db;
    } else {
      this._db = getFirebaseBackend().firestore();
    }
    this._cache = new Map();
  }

  /**
   * Gets the cached copy of the artefact
   * @param {string} key - Key of the artefact
   * @return {any|null} Returns the value of the artefact
   */
  getArtefact(key) {
    if (this._cache.get(key) === undefined) {
      return null;
    } else {
      return this._cache.get(key);
    }
  }

  /**
   * Adds an artefact to the cache, overriding the artefact if it already exists
   * @param {string} key
   * @param {any} value
   */
  setArtefact(key, value) {
    window.console.log("[claimdataservice] setting artefact", key, value);
    this._cache.set(key, value);
  }

  /**
   * Iterates through a key value pair setting them to the cache
   * @param { Object } artefacts - Key value pair to be set to the cache
   */
  setManyArtefacts(artefacts) {
    if (Object.keys(artefacts).length > 0) {
      Object.keys(artefacts).map((key) => {
        this.setArtefact(key, artefacts[key]);
      });
    }
  }
  /**
   * Denormalizes claim data for file and agreements
   * @param {Object} data claimData to be denormalized
   * @param { String } userId
   * @returns { Promise<Object> }
   */
  async parseData(data, userId) {
    const out = {};
    for (const [key, value] of Object.entries(data)) {
      if (!Array.isArray(value) && typeof value === "object" && value !== null) {
        // If the value is a file then let us load it in from Firebase Storage
        if (value.type === "file") {
          out[key] = { store: value };
        } else {
          out[key] = value;
        }
        // For all other cases just append value as a string
      } else if (Array.isArray(value)) {
        let parsedValue = [];
        await Promise.all(
          value.map(async (element) => {
            let parsedElement;
            if (typeof element === "object" && !Array.isArray(element) && element !== null) {
              parsedElement = {};
              for (const [property, propertyValue] of Object.entries(element)) {
                if (propertyValue) {
                  const { path, type, id } = propertyValue;

                  // If the value is a file then let us load it in from Firebase Storage
                  if (type === "file") {
                    console.log("[claimData] propertyValue is", propertyValue);
                    // Fetch the file meta to be passed back
                    const userRef = this._db.collection("users").doc(userId);
                    const fileRef = userRef.collection("files").doc(id);
                    const fileSnapshot = await fileRef.get();
                    const { name } = fileSnapshot.data();

                    const { data: file } = await this.storageService.downloadFile(path, {
                      name: name,
                    });
                    parsedElement[property] = { file: file, store: value }; // [parsedElement] {file: File, store: {type: file, id: ..., path} }
                  } else {
                    parsedElement[property] = propertyValue;
                  }
                }
              }
            } else {
              parsedElement = element;
            }

            parsedValue.push(parsedElement);
          })
        );
        out[key] = parsedValue;
      } else {
        out[key] = value;
      }
    }
    return out;
  }

  /**
   * Returns the cache as an object
   * @return {*} The current cache for this data service
   */
  getCache() {
    // Convert the map to an object
    let cacheObject = Array.from(this._cache).reduce((cacheObject, [key, value]) => {
      cacheObject[key] = value;
      return cacheObject;
    }, {});
    // Return the object
    return cacheObject;
  }
}

/**
 * UserDataService maintains a cached copy of the requesting user's Data
 */
class UserDataService extends DataService {
  _collectionName = "users";
  _userId;

  constructor(userId) {
    super();
    this._userId = userId;

    // Enforce the singleton property
    if (userDataServiceInstances[userId]) {
      return userDataServiceInstances[userId];
    }
    userDataServiceInstances[userId] = this;
  }

  /**
   * Returns the singleton instance of a Data Service
   * @returns {UserDataService}
   */
  static getInstance(userId) {
    if (!userDataServiceInstances[userId]) {
      return null;
    }
    // if (window.console) window.console.log("Returning singleton!");
    return userDataServiceInstances[userId];
  }

  /**
   *
   * @returns {Promise<Object>} Returns a status to indicate if the operation was successful
   */
  async refresh() {
    // Fetch data for requesting user from Firestore

    const userRef = this._db.collection(this._collectionName).doc(this._userId);
    const userSnapshot = await userRef.get();
    if (!userSnapshot.exists) {
      console.log("NO  USER FOUND");
      return { data: null };
    } else {
      const userData = userSnapshot.data();
      const parsedUserData = await this.parseData(userData, this._userId);

      this._cache = new Map(Object.entries(parsedUserData));
      return { data: true };
    }
  }
  /**
   * Updates the Firestore backend to match the current artefact cache
   */
  async update() {
    let store = this.getCache();
    if (window.console) window.console.log("Calling update on user data service userId is: ", { store });

    // Prevent the storage of files on users
    for (const [key, value] of Object.entries(store)) {
      if ((value && value.file instanceof File) || value?.store?.type === "file") {
        delete store[key];
      }

      // These user data points are being handled server side
      if (["workspaces", "permissions", "hasPassword", "passwordChangeDate", "lastLoginDate"].includes(key)) {
        delete store[key];
      }
    }

    const userRef = this._db.collection("users").doc(this._userId);

    await userRef.set(store, { merge: true });

    return { data: true };
  }
}
/**
 * Claims Data Service maintains a cached copy of a requesting user's given claim
 */
class ClaimDataService extends DataService {
  _collectionName = "claims";
  _userId;
  _claimId;

  constructor(userId, claimId) {
    super();
    this._userId = userId;
    this._claimId = claimId;
    // Enforce the singleton property

    if (claimDataServiceInstances[claimId]) {
      return claimDataServiceInstances[claimId];
    }
    if (!claimDataServiceInstances[claimId]) {
      claimDataServiceInstances[claimId] = this;
    }
    claimDataServiceInstances[claimId] = this;

    if (window.console) window.console.log("creating the instnace for cds", this.instance);
  }

  static getInstance(claimId) {
    window.console.log("[claimDataService2] looking for instance", claimId, claimDataServiceInstances);
    if (!claimDataServiceInstances[claimId]) {
      return new ClaimDataService(claimId);
    }
    return claimDataServiceInstances[claimId];
  }

  /**
   * Subscribes to Firestore for real time document updates to the claim document
   */
  getListener() {
    const userRef = this._db.collection("users").doc(this._userId);

    // Fetch claim from requesting user in Firestore
    const claimRef = userRef.collection(this._collectionName).doc(this._claimId);

    return claimRef.onSnapshot;
  }
  /**
   *
   * @returns {Promise<{data: boolean}|null>} Returns a status to indicate if the operation was successful
   */
  async refresh() {
    const db = getFirebaseBackend().firestore();
    const userRef = db.collection("users").doc(this._userId);

    // Fetch claim from requesting user in Firestore
    const claimRef = userRef.collection(this._collectionName).doc(this._claimId);
    const claimSnapshot = await claimRef.get();
    if (!claimSnapshot.exists) {
      return { data: null };
    } else {
      const claimData = claimSnapshot.data();
      const parsedClaimData = await this.parseData(claimData, this._userId);

      if (parsedClaimData.documentId) {
        parsedClaimData.claimId = parsedClaimData.documentId;
      } else {
        parsedClaimData.claimId = this._claimId;
      }

      if (window.console) window.console.log("Parsed claim data is: ", parsedClaimData);
      this._cache = new Map(Object.entries(parsedClaimData));

      console.log("[claimData] - cache is: ", this.getCache());
      return { data: true };
    }
  }

  /**
   * Updates the Firestore backend to match the current artefact cache
   */
  async update() {
    let store = this.getCache();
    store.updatedAt = new Date();
    store.lastUpdated = new Date();

    const userRef = this._db.collection("users").doc(this._userId);
    window.console.log("Updating the claim data..", JSON.parse(JSON.stringify(store)));
    // For each {k,v} in the cache, identify any files
    // which need to be stored in Firebase Storage
    for (const [key, value] of Object.entries(store)) {
      if (store[key] === undefined || store[key] === null) {
        delete store[key];
      } else if (!Array.isArray(value) && typeof value === "object") {
        if (value.file instanceof File || value?.store?.type === "file") {
          // If the file has not already been stored then lets create a new file to store on the user's claim
          if (!value.store) {
            window.console.log("Storing the file fresh");
            // Convert file object to {type: "file", path: "filePath"};
            // Store file in Firebase Storage

            const userPath = `users/${this._userId}`;

            // Extract the file name and extension from the file object
            const file = value.file;
            const extension = mime.extension(file.type);

            // Create a unique FileId for the file using Firestore
            const fileRef = userRef.collection("files").doc();
            const fileId = fileRef.id;
            const filePath = `${userPath}/${fileId}.${extension}`;

            // Upload the file to Firebase Storage
            await this.storageService.uploadFile(filePath, file);

            // Store metadata about the file
            const fileMeta = {
              name: file.name,
              mime: file.type,
              createdAt: this.FieldValue.serverTimestamp(),
              extension: extension,
              path: filePath,
              fileId: fileId,
              claimId: this._claimId,
            };

            console.log("File meta is: ", fileMeta);
            await fileRef.set(fileMeta, { merge: true });

            // Update object to store
            store[key] = { type: "file", path: filePath, id: fileId };

            // Update cache to include the fact that the file has been stored
            this.setArtefact(key, { file: value.file, store: store[key] });
          } else {
            // Not allowing the updating of files just yet
            // deleting it here will just prevent it from being changed on the claim, it won;t actually delete the reference to the file
            delete store[key];
          }
        } else if (Array.isArray(value.file) && value.file.every((file) => file instanceof File)) {
          console.log("Storing multiple files", value);
          const filesStore = [];
          await Promise.all(
            value.file.map(async (file) => {
              const userPath = `users/${this._userId}`;

              const extension = mime.extension(file.type);

              // Create a unique FileId for the file using Firestore
              const fileRef = userRef.collection("files").doc();
              const fileId = fileRef.id;
              const filePath = `${userPath}/${fileId}.${extension}`;

              // Upload the file to Firebase Storage
              await this.storageService.uploadFile(filePath, file);

              // Store metadata about the file
              const fileMeta = {
                name: file.name,
                mime: file.type,
                createdAt: this.FieldValue.serverTimestamp(),
                extension: extension,
                path: filePath,
                fileId: fileId,
                claimId: this._claimId,
              };

              console.log("File meta is: ", fileMeta);
              await fileRef.set(fileMeta, { merge: true });

              const fileStore = { type: "file", path: filePath, id: fileId };

              filesStore.push(fileStore);
            })
          );
          store[key] = filesStore;
          console.log("Setting files", key, filesStore);
          this.setArtefact(key, filesStore);
        } else if (value.type === "agreement") {
          console.log("Saving Agreement", value);
          try {
            const agreementCollection = userRef.collection("claims").doc(this._claimId).collection("agreements");
            let agreementRef;
            if (!value.id) {
              agreementRef = agreementCollection.doc();

              value.id = agreementRef.id;
              await agreementRef.set(value, { merge: true });

              store[key] = { type: "agreement", ...value };
            } else {
              agreementRef = agreementCollection.doc(value.id);
              await agreementRef.set(value, { merge: true });

              store[key] = { type: "agreement", id: value.id, ...value };
            }
          } catch (e) {
            console.log(e);
          }
        }
        // Support for array of objects containing files
      } else if (Array.isArray(value)) {
        await Promise.all(
          value.map(async (element, elementIndex) => {
            if (typeof element === "object" && element !== null) {
              for (const [property, propertyValue] of Object.entries(element)) {
                if (typeof propertyValue === "object" && propertyValue !== null) {
                  if (propertyValue.file instanceof File) {
                    // If the file has not already been stored then lets create a new file to store on the user's claim
                    if (!propertyValue.store) {
                      // Convert file object to {type: "file", path: "filePath"};
                      // Store file in Firebase Storage

                      const userPath = `users/${this._userId}`;

                      // Extract the file name and extension from the file object
                      const file = propertyValue.file;
                      const extension = mime.extension(file.type);

                      // Create a unique FileId for the file using Firestore
                      const fileRef = userRef.collection("files").doc();
                      const fileId = fileRef.id;
                      const filePath = `${userPath}/${fileId}.${extension}`;

                      // Upload the file to Firebase Storage
                      await this.storageService.uploadFile(filePath, file);

                      // Store metadata about the file
                      const fileMeta = {
                        name: file.name,
                        mime: file.type,
                        createdAt: this.FieldValue.serverTimestamp(),
                        extension: extension,
                        path: filePath,
                        fileId: fileId,
                        claimId: this._claimId,
                      };

                      console.log("File meta is: ", fileMeta);

                      await fileRef.set(fileMeta, { merge: true });

                      // Update object to store
                      store[key][elementIndex][property] = {
                        type: "file",
                        path: filePath,
                        id: fileId,
                      };

                      let valueCopy = JSON.parse(JSON.stringify(value));

                      valueCopy[elementIndex][property] = {
                        file: propertyValue.file,
                        store: store[key][elementIndex][property],
                      };
                      // Update cache to include the fact that the file has been stored
                      this.setArtefact(key, valueCopy);
                    } else {
                      // Not allowing the updating of files just yet
                      store[key][elementIndex][property] = propertyValue.store;
                    }
                  }
                }
              }
            }
          })
        );
      }
    }

    const claimRef = userRef.collection("claims").doc(this._claimId);

    console.log("[claimData] - Claim cache", this.getCache());
    console.log("[claimData] - storing claim data", store);
    await claimRef.set(store, { merge: true });

    return { data: true };
  }
}

// Primitive javascript object is responsible for holding the singleton for each claim
// For example it may have a {YB10AWF: claimDataService, D2: claimDataService}
// Helps support the use of multiple claims when needed and prevent bugs
// which could occur when using multiple forms for different claims
let claimDataServiceInstances = {};

let userDataServiceInstances = {};
export { DataService, UserDataService, ClaimDataService };
