import * as Sentry from "@sentry/vue";
import { Route } from "vue-router";
import { getUserHelper, UserHelper } from "@/helpers/ClaimsGate/UserHelper";

export class SentryError extends Error {
  constructor(message: string, name: string) {
    super(message);
    this.name = name;
  }
}

export class SentryService {
  public sentry: typeof Sentry;
  private userService: UserHelper;
  private userId: string;

  private setUp() {
    this.sentry = Sentry;

    this.userService = getUserHelper();
    this.userId = this.userService.getUserId();
  }

  /**
   * Will pass a error log to sentry, adding some useful information from router, and the user id if exists
   * @param name context name
   * @param exception Error object
   * @param route Vue route object
   */
  captureException(name: string, exception: any, route?: Route) {
    try {
      this.setUp();
      Sentry.setUser({ id: this.userId });

      if (typeof exception === "string") {
        exception = new SentryError(exception, name);
      }
      if (Array.isArray(exception)) {
        exception = new SentryError(exception.join(", "), name);
      }
      if (typeof exception === "object") {
        // Could be a ClaimsGate Error
        exception = new SentryError(exception?.message, exception?.id ?? exception?.name);
      }

      let routeVals = {};
      if (route) {
        routeVals = {
          params: route.params,
          query: route.query,
          path: route.fullPath,
        };
      }

      Sentry.setContext(name, {
        ...routeVals,
        message: exception?.message ?? exception,
        //auth: JSON.parse(localStorage.getItem("auth.currentUser"))
      });

      window.console.log("Sentry firing", name, exception);
      Sentry.captureException(exception);
    } catch (e) {
      Sentry.captureException(e);
    }
  }
}

export function createSentryService() {
  return new SentryService();
}
