// Services
import { WorkspaceService } from "@claimsgate/core";
import { getFirebaseBackend } from "../../authUtils.js";

// Types
import { Route } from "vue-router";

/** Routes which can be both on CG and on custom domain */
const routes = {
  Login: { cg: "login", workspace: "login" },
  Logout: { cg: "logout", workspace: "logout" },
  Me: { cg: "Me", workspace: "Me" },
  Settings: { cg: "Settings", workspace: "Settings" },
  Claims: { cg: "Claims", workspace: "WorkspaceClaims" },
};

class RedirectHelper {
  $router;
  $route;

  constructor(router, route) {
    this.$router = router;
    this.$route = route;
  }

  /**
   * Redirects the user to Custom domain or CG page depending on the current state
   */
  async redirect(route: string, navigationWorkspaceId: string) {
    if (navigationWorkspaceId) {
      await this.$router.push({
        name: routes[route].workspace,
        params: { workspaceId: navigationWorkspaceId },
      });
      if (this.$route.name === routes[route].workspace) {
        this.$router.go(); // reloads the page
      }
      return;
    }

    // Attempt to redirect the user to WorkspaceClaims using workspaceId from domain
    if (window.location.host !== getClaimsGateDomain()) {
      const workspaceService = new WorkspaceService(getFirebaseBackend().firebase());
      const { data: workspaceId } = await workspaceService.getWorkspaceIdFromSubdomain(window.location.host);

      if (workspaceId) {
        await this.$router.push({ name: routes[route].workspace, params: { workspaceId: workspaceId } });
        if (this.$route.name === routes[route].workspace) {
          this.$router.go();
        }
        return;
      }
    }

    await this.$router.push({ name: routes[route].cg });
    if (this.$route.name === routes[route].cg) {
      this.$router.go();
    }

    return;
  }
}

/** Determines whether we are currently on a workspace domain page or not */
export function onWorkspaceDomain(route: Route): boolean {
  if (window.location.host !== getClaimsGateDomain() && (route.name === "404page" || route.name === "continue")) {
    return true;
  }

  return (
    route.name === "Track" ||
    route.name === "form" ||
    route.name === "WorkspaceClaims" ||
    !!route.query.workspaceId ||
    (route.name === "login" && !!route.params.workspaceId) ||
    (route.name === "Me" && !!route.params.workspaceId) ||
    (route.name === "Settings" && !!route.params.workspaceId)
  );
}

/** Determines whether we are currently on a workspace branded page or not */
export function onWorkspaceBranded(route: Route): boolean {
  if (window.location.host !== getClaimsGateDomain()) {
    return true;
  }

  return (
    route.name === "continue" ||
    route.name === "404page" ||
    route.name === "Track" ||
    route.name === "form" ||
    route.name === "Builder" ||
    route.name === "WorkspaceClaims" ||
    !!route.query.workspaceId ||
    (route.name === "login" && !!route.params.workspaceId) ||
    (route.name === "Me" && !!route.params.workspaceId) ||
    (route.name === "Settings" && !!route.params.workspaceId)
  );
}

/** Returns the domain for Claims Gate based upon the environment **/
export function getClaimsGateDomain(): string {
  // ? do we have a better way of checking if we are on localhost?
  if (window.location.host === "localhost") {
    return "localhost";
  } else if (window.location.host === "localhost:1024") {
    return "localhost:1024";
  } else if (window.location.host.includes("claimsgate-4cda5--preview")) {
    return window.location.host;
  } else if (process.env.VUE_APP_PROJECTId === "dev-claims-cake") {
    return "dev-claims-cake.web.app";
  } else if (process.env.VUE_APP_PROJECTId === "claimsgate-4cda5") {
    return "claimsgate.co.uk";
  } else {
    return "claimsgate.co.uk";
  }
}

export function getClaimsGateSchemeAndDomain(): string {
  if (window.location.host === "localhost") {
    return "http://localhost";
  }

  return "https://" + getClaimsGateDomain();
}

export function onStagingEnvironment(): boolean {
  return process.env.VUE_APP_PROJECTId === "dev-claims-cake";
}

export { RedirectHelper };
